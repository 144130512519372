import {Button, Center} from '@mantine/core';
import * as React from 'react';
import {useInView} from 'react-intersection-observer';
import {CenteredLoading} from './CenteredLoading';

export const InifiniteScrollTrigger: React.FC<{
  hasNext: boolean;
  isLoading: boolean;
  onTrigger: () => void;
  loading?: React.ReactNode;
  complete?: React.ReactNode;
}> = ({hasNext, isLoading, onTrigger, loading, complete}) => {
  const {ref, inView} = useInView();

  const hasNextRef = React.useRef(hasNext);
  React.useEffect(() => {
    hasNextRef.current = hasNext;
  }, [hasNext]);

  const isLoadingRef = React.useRef(isLoading);
  React.useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  const onTriggerRef = React.useRef(onTrigger);
  React.useEffect(() => {
    onTriggerRef.current = onTrigger;
  }, [onTrigger]);

  React.useEffect(() => {
    if (inView && (!isLoadingRef.current || !hasNextRef.current)) {
      onTriggerRef.current();
    }
  }, [inView]);

  return (
    <Center ref={ref}>
      {isLoading && (loading ?? <CenteredLoading />)}
      {hasNext && !isLoading && <Button onClick={onTrigger}>Load More</Button>}
      {!hasNext && complete}
    </Center>
  );
};
