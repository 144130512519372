import * as React from 'react';
import {EntryType} from '../../entries/Entries';

export type FilterState = {
  query?: string;
  queryEmbedding?: number[];
  entryTypes?: EntryType[];
  after?: Date;
  before?: Date;
};

export type FilterContext = {
  state: FilterState;
  setState: (value: FilterState | ((v: FilterState) => FilterState)) => void;
};

const FilterContext = React.createContext<FilterContext>({
  state: {},
  setState: () => {},
});

export const __useFilterContext = (): FilterContext => {
  return React.useContext(FilterContext);
};

export const useFilterState = (): FilterState => {
  return __useFilterContext().state;
};

export const FilterContextProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [state, setState] = React.useState<FilterState>({});
  const contextValue = React.useMemo(
    () => ({
      state,
      setState,
    }),
    [state],
  );

  React.useEffect(() => {
    console.log('state: ', state);
  }, [state]);

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};
