import {Card, Group, Skeleton} from '@mantine/core';
import * as React from 'react';

export const EntryPreviewGlimmer: React.FC = () => {
  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Group align="center">
        <Skeleton height={32} width={32} />
        <Skeleton height={20} width={150} />
      </Group>
      {new Array(4).fill(null).map((_, i) => (
        <Skeleton key={i} height={20} mt={6} />
      ))}
    </Card>
  );
};
