import * as React from 'react';
import {graphql, usePaginationFragment, useFragment} from 'react-relay';
import {EntryFeed_user$key} from '../../queries/__generated__/EntryFeed_user.graphql';
import {Box} from '@mantine/core';
import {EntryFeed_query$key} from '../../queries/__generated__/EntryFeed_query.graphql';
import dynamic from 'next/dynamic';
import {InifiniteScrollTrigger} from '../ui/InfiniteScrollTrigger';
import {EntryPreviewGlimmer} from './EntryPreviewGlimmer';
import {FilterContextProvider, useFilterState} from './EntryFilterContext';

const EntryPreview = dynamic(
  async () => (await import('./EntryPreview.client')).EntryPreview,
  {
    ssr: false,
    loading: () => <EntryPreviewGlimmer />,
    // suspense: true,
  },
);

const EntryFilterBar = dynamic(
  async () => (await import('./EntryFilterBar')).EntryFilterBar,
  {
    ssr: false,
  },
);

const EntryFeedPagination: React.FC<{user$key: EntryFeed_user$key}> = ({
  user$key,
}) => {
  const {data, loadNext, hasNext, isLoadingNext, refetch} =
    usePaginationFragment(
      graphql`
        fragment EntryFeed_user on User
        @argumentDefinitions(
          first: {type: "Int", defaultValue: 10}
          cursor: {type: "String", defaultValue: null}
          filter: {type: "EntryConnectionFilter", defaultValue: null}
        )
        @refetchable(queryName: "EntryFeedPaginationQuery") {
          entryConnection(first: $first, after: $cursor, filter: $filter)
            @connection(key: "EntryFeed_user_entryConnection") {
            edges {
              node {
                id
                type
                ...EntryPreview_entry
              }
            }
          }
        }
      `,
      user$key,
    );

  const state = useFilterState();
  const initialRefetch = React.useRef(true);
  const filters = React.useMemo(
    () => ({
      search: state.queryEmbedding,
      type: state.entryTypes,
    }),
    [state.queryEmbedding ?? null, state.entryTypes ?? null],
  );

  React.useEffect(() => {
    console.log('effect: ', filters);
    if (initialRefetch.current) {
      initialRefetch.current = false;
      return;
    }
    console.log('preloaded: ');

    console.log('refetching: ', filters);

    refetch({
      filter: filters,
    });
  }, [refetch, filters]);

  const nodes = (data?.entryConnection?.edges ?? [])
    .map(edge => edge?.node)
    .filter(Boolean);

  if (nodes.length === 0) {
    // TODO: Null state
    return null;
  }

  return (
    <div>
      {nodes.map(node => (
        <Box key={node.id} mb="lg">
          <EntryPreview entry$key={node} />
        </Box>
      ))}
      {/* {hasNext && (
        <Button
          onClick={() => {
            loadNext(10);
          }}
        >
          Load More
        </Button>
      )} */}
      {hasNext && (
        <InifiniteScrollTrigger
          hasNext={hasNext}
          isLoading={isLoadingNext}
          onTrigger={() => loadNext(10)}
        />
      )}
    </div>
  );
};

const EntryFeedImpl: React.FC<{query$key: EntryFeed_query$key}> = ({
  query$key,
}) => {
  const data = useFragment(
    graphql`
      fragment EntryFeed_query on Query {
        me {
          user {
            ...EntryFeed_user
          }
        }
      }
    `,
    query$key,
  );

  const user = data?.me?.user;
  if (user == null) {
    return null;
  }

  return <EntryFeedPagination user$key={user} />;
};

export const EntryFeed: React.FC<
  React.ComponentProps<typeof EntryFeedImpl> & {withFilters?: boolean}
> = ({withFilters = true, ...feedProps}) => {
  if (!withFilters) {
    return <EntryFeedImpl {...feedProps} />;
  }

  return (
    <FilterContextProvider>
      <EntryFilterBar />
      <EntryFeedImpl {...feedProps} />
    </FilterContextProvider>
  );
};
